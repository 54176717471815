.contactt {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

}

.contactt h1 {
    text-align: center;
    color: #ffbea0;
    line-height: 45px;
    letter-spacing: 3px;
}

.innerform {
    display: flex;
    flex-direction: column;
    gap: 27px;
    align-items: center;

}

.html_form label {
    font-size: 25.2px;
    line-height: 50px;
    font-weight: bold;
    color: #ffa746;
}

.html_form input[type="text"],
.html_form input[type="email"] {
    font-size: 22.4px;
    height: 18px;
    padding: 15.2px 15.8px;
    width: 350px;
    height: 70px;
    border: 1px solid #cd2424;
    border-radius: 10px;
    background: #ffecba;
}

.html_form textarea {
    font-size: 22.4px;

    padding: 15.8px;
    width: 49rem;
    height: 200px;
    border: 1px solid #cd2424;
    border-radius: 25px;
    /* margin-bottom: 1px; */
    background: #fff3d4;
}

.per_info {
    display: flex;
    gap: 87px;
    width: 100%;
}

.my_btn {
    display: block;
    padding: 9px 25px;
    font-size: 2rem;
    width: 22rem;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    border: 0px solid #fc9900;
    color: white;
    margin: 39px;

    font-weight: 0;
    background-color: white;

    /* height: 52px; */
    background-color: #d30c53;
}

.my_btn:hover {
    cursor: pointer;
    /* border: 2px solid #fe0000; */
    color: white;
    background-color: #ff025b;
}

.name {
    display: flex;
    gap: 9.6px;
    flex-direction: column;
}

.email {
    display: flex;
    gap: 9.6px;
    flex-direction: column;

}

.msg {
    display: flex;
    gap: 9.6px;
    flex-direction: column;
}



@media only screen and (min-width: 1024px) {
    .contactt h1 {
        font-size: 2rem;
    }

    .html_form input[type="text"],
    .html_form input[type="email"] {
        font-size: 20.4px;


    }

    .html_form textarea {
        font-size: 20.4px;
    }
}


@media only screen and (min-width: 949px) and (max-width: 1024px) {
    .contactt h1 {
        font-size: 2rem;
    }

    .html_form input[type="text"],
    .html_form input[type="email"] {
        font-size: 20.2px;

    }

    .html_form label {
        font-size: 23.2px;
        /* color: red; */

    }

    .html_form textarea {
        font-size: 20.2px;
    }
}


@media only screen and (min-width: 799px) and (max-width: 948px) {
    .contactt h1 {
        font-size: 2rem;
    }

    .html_form input[type="text"],
    .html_form input[type="email"] {
        font-size: 20px;

    }

    .html_form label {
        font-size: 22.2px;
        /* color: red; */

    }

    .html_form textarea {
        font-size: 20px;
    }
}

@media only screen and (min-width: 332px) and (max-width: 911.2px) {
    .contactt {
        padding-top: 7.6rem;
    }
}

@media only screen and (min-width: 569px) and (max-width: 798px) {
    .per_info {
        gap: 70px;
    }

    .contactt h1 {
        font-size: 1.5rem;
    }

    .html_form input[type="text"],
    .html_form input[type="email"] {
        font-size: 19px;
        width: 230px;
        height: 50px;

    }

    .html_form label {
        font-size: 20px;
        /* color: red; */

    }

    .html_form textarea {
        font-size: 19px;
        width: 530px;
        height: 155px;
    }

    .my_btn {
        width: 15rem;
        font-size: 28px;
    }
}


@media only screen and (min-width: 300px) and (max-width: 568px) {
    .per_info {
        gap: 20px;
    }

    .contactt h1 {
        font-size: 28px;
    }

    .html_form input[type="text"],
    .html_form input[type="email"] {
        font-size: 18px;
        width: 42vw;

    }

    .html_form label {
        font-size: 20px;
        /* color: red; */

    }

    .html_form textarea {
        font-size: 19px;
        width: 90vw;
    }

    .my_btn {
        width: 12rem;
        font-size: 24px;
    }
}

@media only screen and (max-width: 331.2px) {
    .contactt {
        padding-top: 5.6rem;
    }
}


@media only screen and (min-width: 230px) and (max-width: 299px) {
    .per_info {
        gap: 16px;
    }

    .contactt h1 {
        font-size: 20px;
    }

    .html_form input[type="text"],
    .html_form input[type="email"] {
        font-size: 14px;
        width: 42vw;
        height: 20vw;

    }

    .html_form label {
        font-size: 14px;
        /* color: red; */

    }

    .html_form textarea {
        font-size: 14px;
        width: 87vw;
        ;
    }

    .my_btn {
        width: 10rem;
        font-size: 18px;
        margin: 0px;
        margin-bottom: 17px;
    }

    /* .contactt {
      margin-top: 3rem;
    } */
}


@media only screen and (min-width: 100px) and (max-width: 229.7px) {
    .per_info {
        gap: 14px;
        flex-direction: column;
    }

    /* .contactt {
      margin-top: 3rem;
    } */

    .contactt h1 {
        font-size: 16px;
    }

    .html_form input[type="text"],
    .html_form input[type="email"] {
        font-size: 14px;
        width: 93vw;
        height: 30vw;

    }

    .html_form label {
        font-size: 14px;
        /* color: red; */

    }

    .html_form textarea {
        font-size: 14px;
        width: 90vw;
        height: 95vw;
    }

    .my_btn {
        /* height: 40px; */
        width: 90px;
        font-size: 14px;
        margin: 0px;
        margin-bottom: 17px;
        text-align: center;
    }
}