/* About */

.about-section {
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
    color: white !important;
}

.card {
    word-wrap: break-word;
}

.tech-icons {
    font-size: 4.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 10px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}


.heading1 {
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 50px;
}

.heading1 h1 {
    font-size: 2.1em;
    padding-bottom: 20px;
}

.aboutCard-section {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    display: flex;

    /* flex-wrap: wrap; */

}

/* AboutCard */

.quotes {
    padding-left: 100px;
    font-size: 20px;
    color: rgb(155 126 172)
}

.quote-card-view {
    border: none !important;
    color: white !important;
    background-color: transparent !important;
    cursor: default;
}

.blockquote {
    padding-left: 55px;
    padding-right: 20px;
}

.blockquote-footer {
    font-size: 20px;
}

.blockquote-footer:before {
    content: "— ";
}

.intro-part {
    justify-content: center;
    align-items: center;
    text-align: justify;
    font-size: 20px;
    line-height: 1.5;
}

.about-activity {
    /* list-style: none !important; */
    /* cursor: pointer; */
    text-align: left !important;
    padding-left: 1px !important;
}

.about-activity img {
    height: 20px;
    width: 20px;
}

.activity {
    padding-left: 40px;
}

.tech-icons img {
    height: 50px;
    width: 50px;
}

.tech-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    /* display: grid;
    grid-template-columns: repeat(5, 1fr); */
    /* grid-template-rows: repeat(3, 1fr); */
    /* padding-left: 80px;
    padding-right: 50px; */
    padding: 0 20px;
    padding-bottom: 30px;

}

.tech-icons {
    width: 180px;
    height: 100px;
}

.tech-icons {
    width: 13.7rem;
    height: auto;
}

.lap-img {
    padding-top: 120px;
    padding-bottom: 50px;
}

.lap-img img {
    height: 300px;
    width: 450px;
}

@media (max-width: 991.5px) {
    .lap-img img {
        height: 200.41px;
        width: 285.66px;
    }

    .quotes {
        padding-left: 0px;
    }

}


/* .first-row,
.second-row,
.third-row {
    display: flex;
} */



/* .tech-icons img :hover {
    text-anchor: start;
} */


@media (max-width: 767px) {
    .tech-icons {
        margin: 10px !important;
    }
}

.tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}

.tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
}





@media (max-width: 767px) {
    .about-img {
        padding-top: 0 !important;
    }
}



@media only screen and (max-width: 1182.5px) {
    .tech-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 767.5px) {
    .tech-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
    }

    .aboutCard-section {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
    }

    .about-section {
        padding-top: 5rem !important;
    }

    .blockquote {
        padding-left: 45px;
        padding-right: 45px;
    }
}

@media only screen and (max-width: 520.5px) {

    .tech-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
    }

    .tech-icons {
        width: 9.7rem;
        height: auto;
    }

    .aboutCard-section {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
    }

    .about-section {
        padding-top: 5rem !important;
    }

    .blockquote {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 393.5px) {

    .tech-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
    }

    .tech-icons {
        width: 6.7rem;
        height: auto;
    }

    .aboutCard-section {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
    }

    .about-section {
        padding-top: 5rem !important;
    }

    .blockquote {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 296.5px) {

    .tech-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
    }

    .tech-icons {
        width: 4.7rem;
        height: auto;
    }

    .aboutCard-section {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
    }

    .about-section {
        padding-top: 5rem !important;
    }

    .blockquote {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 296.5px) {
    .lap-img img {
        height: 180.41px;
        width: 200.66px;
    }

    .heading1 h1 {
        font-size: 26px;
    }

    .blockquote {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0;
    }

    .project-heading {
        font-size: 26px !important;
    }
}

@media (max-width: 231.5px) {
    .lap-img img {
        height: 180.41px;
        width: 200.66px;
    }

    .heading1 h1 {
        font-size: 26px;
    }

    .blockquote {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0;
    }

    .tech-icons {
        width: 2.7rem;
        height: auto;
    }

    .tech-container {
        padding: 0;
    }

    .heading1 h1 {
        font-size: 22px;
    }

    .tech-icons {
        width: 4.7rem;
    }

    .tech-icons img {
        height: 30px;
        width: 30px;
        align-self: center;
    }
    .activity {
        padding-left: 0px;
    }

}


@media (max-width: 200.5px) {
    .lap-img img {
        height: 146.41px;
        width: 149.66px;
    }
    .heading1 h1 {
        font-size: 20px;
    }
    .intro-part {
        font-size: 18px;
    }
    .quotes {
        font-size: 18px;
    }
}