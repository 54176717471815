.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 8rem !important;
    position: fixed !important;
    top: 0;
    font-size: 1.2rem !important;
    width: 100%;
    z-index: 1000;
    transition: all 0.3s ease-out 0s !important;
}

.sticky {
    background-color: #1b1a2ea9 !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
    backdrop-filter: blur(15px) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 8rem !important;
    position: fixed !important;
    top: 0;
    font-size: 1.2rem !important;
    width: 100%;
    z-index: 1000;
    transition: all 0.3s ease-out 0s !important;
}

.logo-div {
    display: block;
}

.logo-div img {
    height: 50px;
    width: 60px;
}

.navItems {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
    /* padding-right: 17rem; */
}

.navLink {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navLink img {
    margin-right: 5px;
}

.btn {
    cursor: pointer;
    margin-top: 4px;
    background-color: rgb(190, 41, 162);
    border-radius: 5px;
}

.btn img {
    margin: 7px;
}

.hamburgerBtn {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
    background-color: #000;
}


@media only screen and (max-width: 1285px) and (min-width: 1047px) {
    .navbar {
        padding: 1rem 4rem !important;
    }

    .sticky {
        padding: 1rem 4rem !important;
    }

    /* .navItems {
        padding-right: 9rem;
    } */

}

@media only screen and (max-width: 1046.5px) and (min-width:912px) {
    .navbar {
        padding: 1rem 3rem !important;
    }

    .sticky {
        padding: 1rem 3rem !important;
    }

    .navItems {
        gap: 20px;
        /* padding-right: 7rem; */
    }

}

@media only screen and (max-width: 912.5px)and (min-width:768px) {
    .navbar {
        padding: 1rem 1rem !important;
    }

    .sticky {
        padding: 1rem 1rem !important;
    }

    .navItems {
        gap: 3px;
        /* padding-right: 3rem; */
    }

    .btn img {
        margin: 5px;
    }

    .btn {
        height: 2.2rem;
        width: 5rem;
    }

    .contact {
        padding-right: 0.5rem;
    }
}



@media only screen and (max-width: 767.5px) {

    .btn {
        margin-top: 0rem;
    }

    .navItems {
        display: none;
    }

    .bar {
        background-color: rgb(155 14 175);
    }



    .btn {
        cursor: pointer;
        margin-top: 0px;
        background-color: rgb(190, 41, 162);
        border-radius: 5px;
        width: 6rem;
    }

    .btn img {
        margin: 7px;
    }

    .navbar,
    .sticky {
        padding: 0.5rem 1.9rem !important;
    }

    .show {
        display: flex;
        flex-direction: column;
        text-align: right;
        order: 2;
    }


    .hamburgerBtn.show {
        position: absolute;
        right: 0px;
    }

    .navItems {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        z-index: 998;
        transition: opacity 0.3s ease-in-out;
        /* padding-left: 60px; */
        padding-top: 70px;
        /* height: 100%; */
        padding-bottom: 50px;
    }

    .navbar {
        background-color: rgba(38, 57, 74, 0.87) !important;
    }

    .sticky {
        background-color: rgba(38, 57, 74, 0.87) !important;
    }

    .navbar.sticky {
        background-color: rgba(38, 57, 74, 0.87) !important;
    }

    .navItems.show {
        background-color: rgba(38, 57, 74, 0.87) !important;
        /* background-color: rgba(27, 26, 46, 0.663); */
        transition: all 0.3s ease-out 0s;
        /* box-shadow: 0px 10px 10px 0px rgba(38, 57, 74, 0.87); */
        backdrop-filter: blur(100rem);
    }

    .logo-div {
        z-index: 1000;
    }

    .hamburgerBtn {
        display: block;
        position: absolute;
        z-index: 999;
        cursor: pointer;
        background-color: transparent;
        /* width: 33px; */
        /* height: 40px; */
        padding-right: 3rem;
        right: 0px;
        /* margin-right: 10px; */
        order: 1;
    }

    .bar {
        display: block;
        height: 5px;
        width: 35px;
        /* height: 3px;
        width: 45px; */
        border: 0px solid #000;
        border-radius: 3px;
        /* border: 2px solid #000;
        border-radius: 3px; */
        opacity: 1;
        left: 0;
        /* transition: all 0s ease; */
        /* transition: box-shadow .15s ease-in-out; */
        transition: transform .25s ease-in-out !important;
    }

    .navItems.show {
        opacity: 1;
        pointer-events: auto;
        text-align: center;
    }

    .hamburgerBtn span:nth-child(1) {
        top: 5px;
    }

    .hamburgerBtn span:nth-child(2) {
        top: 11px;
    }

    .hamburgerBtn span:nth-child(3) {
        top: 17px;
    }

    .hamburgerBtn.show span:nth-child(1) {
        transform: translate(0, 11px) rotate(135deg);
    }

    .hamburgerBtn.show span:nth-child(2) {
        opacity: 0;
    }

    .hamburgerBtn.show span:nth-child(3) {
        transform: translate(0, -9px) rotate(-135deg);
    }
}

@media only screen and (max-width: 332px) {

    .navbar,
    .sticky {
        /* padding: 0.5rem 1rem !important; */
        padding: 0.1rem 0.15rem !important;
    }

    .hamburgerBtn {
        padding-right: 2rem;
    }

    .navItems {
        padding-top: 90px;
    }
}


@media only screen and (max-width: 172px) {

    .navbar,
    .sticky {
        padding: 0.1rem 0.15rem !important;
    }

    .hamburgerBtn {
        padding-right: 0.3rem;
    }

    .navItems {
        padding-top: 90px;
    }

    .bar {
        display: block;
        height: 4px;
        width: 26px;
        border: 0px solid #000;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        transition: transform .25s ease-in-out !important;
    }

    .logo-div img {
        height: 40px;
        width: 50px;
    }

    .hamburgerBtn span:nth-child(1) {
        top: 5px;
    }

    .hamburgerBtn span:nth-child(2) {
        top: 11px;
    }

    .hamburgerBtn span:nth-child(3) {
        top: 17px;
    }

    .hamburgerBtn.show span:nth-child(1) {
        transform: translate(0, 9px) rotate(135deg);
    }

    .hamburgerBtn.show span:nth-child(2) {
        opacity: 0;
    }

    .hamburgerBtn.show span:nth-child(3) {
        transform: translate(0, -9px) rotate(-135deg);
    }
}