.content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
    color: white !important;
    padding-left: 100px;
}

.purple {
    color: #c770f0 !important;
}

.heading {
    display: flex;
    flex-wrap: wrap;
    padding-left: 60px;
}

.heading h1 {
    font-weight: 500;
    font-size: 34px;
    line-height: 1.2;
    letter-spacing: 2px;
    margin-bottom: .5rem;
    /* padding-left: 35px; */
}

.home-about-body {
    font-size: 1.2em;
    padding-top: 35px;
    text-align: justify;
}

.social-links {
    display: flex;
    justify-content: center;
    /* padding-inline-start: 0 !important; */
}

.social-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    height: 50px;
    width: 50px;
}

.social-icon {
    transition: 0.5s !important;
    margin: 20px;
}

.social-icon img {
    height: 40px;
    width: 40px;
}

.img-avatar {
    padding-top: 4em;
    padding-left: 60px;
}

.home-social h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: 1px;
    color: #fff;
}



.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
}

.home-about {
    text-align: center;
}


.social-icon {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.1s !important;
}

.social-icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #68187a;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
}

.social-icon:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #801f95;
}

.social-icon:hover {
    color: #87209e;
    box-shadow: 0 0 5px #87209e;
    text-shadow: 0 0 2px #87209e;
}



/* @media only screen and (min-width: 990px) { */

/* .home-about-body {
        padding-left: 2rem;
    }

    .img-avatar {
        padding-top: 3rem;
        padding-left: 10rem;
    } */




/* .heading {
        padding-left: 0px;
    }

    .heading h1 {
        padding-left: 0px;
    }

    .content {
        padding-left: 75px !important;
    } */






/* .heading h1 {
        padding-left: 30px;
    } */

/* .wrapper {
        padding-left: 30px;
    } */
/* } */

@media only screen and (min-width: 768px) and (max-width: 990px) {
    .content {
        padding-left: 75px;
    }

    .img-avatar {
        padding-left: 30px;
    }

    .heading h1 {
        font-size: 30px;
        font-weight: bold;
    }

    .heading {
        padding-left: 0;
    }
}

@media only screen and (max-width: 767.5px) {
    .content {
        display: flex;
        flex-direction: column;
        padding: 20px 85px;
    }

    .img-avatar {
        padding: 3em 0em;
    }

    .home-about-section {
        padding-top: 0px !important;
    }

}


@media only screen and (min-width : 572px) and (max-width: 767.5px) {
    .heading {
        padding-left: 0;
        padding-left: 10%;
    }

    .home-about-section {
        padding-top: 0px !important;
    }
}

@media only screen and (max-width: 572.5px) {

    .home-about-section {
        padding-top: 0px !important;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        text-align: center;
    }

    .home-about-body {
        font-size: 1.2em;
        padding: 35px 20px;

        text-align: justify;
    }
}

@media only screen and (min-width: 150px) and (max-width: 309.5px) {
    .content {
        display: flex;
        flex-direction: column;
        padding: 0px 0px;
        text-align: center;
    }
    .home-about-body{
        padding: 20px;
    }

    .home-social h1 {
        font-weight: 900;
        font-size: 18px;
    }

    .heading {
        padding-left: 0;
    }

    .image-container {
        padding-top: 4.5rem;
        padding-left: 0px;
    }

    .Typewriter__wrapper {
        font-size: 20px !important;
        font-weight: 500 !important;
        position: absolute !important;
    }

    .img-avatar {
        height: 106px;
        width: 148px;
        padding: 0em 0em;
    }

    .heading h1 {
        font-weight: 900;
        font-size: 18px;
        letter-spacing: 1px;
    }

    .social-icon img {
        height: 20px;
        width: 20px;
    }

    .social-icon {
        position: relative !important;
        display: inline-block !important;
        width: 20px !important;
        height: 20px !important;
        text-align: center !important;
        font-size: 1.2em !important;
        line-height: 1em !important;
        background: rgba(255, 255, 255, 0.972) !important;
        border-radius: 50% !important;
        transition: 0.1s !important;
        transition: 0.5s !important;
        margin: 16px;
    }
    .icons{
        padding-top: 20px;
    }
}