#home {
    background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}

.home-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.home-header {
    padding-left: 100px;
}

.home-header div {
    padding-left: 27px;
}

.image-container {
    padding-left: 100px;
}

.img {
    height: 500px;
    width: 500px;
}

.home-content {
    padding-left: 100px;
}






.wave {
    animation-name: wave-animation;
    /* Refers to the name of your @keyframes element below */
    animation-duration: 2.1s;
    /* Change to speed up or slow down */
    animation-iteration-count: infinite;
    /* Never stop waving :) */
    transform-origin: 70% 70%;
    /* Pivot around the bottom-left palm */
    display: inline-block;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(14deg);
    }

    /* The following five values can be played with to make the waving more or less extreme */
    20% {
        transform: rotate(-8deg);
    }

    30% {
        transform: rotate(14deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(0deg);
    }

    /* Reset for the last half to pause */
    100% {
        transform: rotate(0deg);
    }
}

#tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
}

.home-header {
    padding-top: 80px !important;
}

.home-section {
    position: relative;
    z-index: -1;
    /* background-image: var(--image-gradient), url(./Assets/home-bg.jpg); */
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
}

.home-content {
    padding: 9rem 0 2rem !important;
    color: whitesmoke;
    text-align: left;
}

.heading-main {
    font-size: 2.4em !important;
    padding-left: 50px !important;
}

.heading-name {
    font-size: 2.5em !important;
    padding-left: 50px !important;
}

.main-name {
    color: #cd5ff8;
}

.Typewriter__wrapper {
    font-size: 2.2em !important;
    color: #be6adf !important;
    font-weight: 600 !important;
}

.Typewriter__cursor {
    font-size: 2.4em !important;
    color: #b562d6 !important;
}

@media (max-width: 767px) {
    .Typewriter__wrapper {
        font-size: 1.4em !important;
        font-weight: 500 !important;
        position: absolute !important;
    }

    .Typewriter__cursor {
        display: none !important;
    }
}

@media only screen and (max-width: 990.5px) {
    .img {
        height: 20rem;
        width: 20rem;
    }

    .home-header {
        padding-left: 1.5rem;
    }

    .home-header div {
        padding-left: 1.5rem;
    }

    .image-container {
        padding-left: 100px;
    }

    .home-content {
        padding-left: 30px;
    }
}

@media only screen and (max-width: 767.5px) {
    .heading {
        padding-left: 0;
        padding-left: 10%;
    }

    .home-content {
        display: flex;
        flex-direction: column;
    }

    .home-header {
        padding: 0px !important;
    }

    .home-content {
        padding: 9rem 5.8rem 6rem !important;
    }

    .image-container {
        padding-top: 4.5rem;
        padding-left: 0px;
    }

    .img {
        height: 30rem;
        width: 30rem;
    }

    .home-header div {
        font-size: 28px;
        padding-left: 1.62rem;
    }

    /* .home-about-section {
        padding-top: 0px !important;
    } */
}


@media only screen and (min-width : 572px) and (max-width: 767.5px) {
    .heading {
        padding-left: 0;
        padding-left: 10%;
    }

    .home-content {
        display: flex;
        flex-direction: column;
    }

    .home-header {
        padding: 0px !important;
    }

    .home-content {
        padding: 9rem 5.8rem 6rem !important;
    }

    .image-container {
        padding-top: 4.5rem;
        padding-left: 0px;
    }

    .img {
        height: 30rem;
        width: 30rem;
    }

    .home-header div {
        font-size: 28px;
        padding-left: 1.62rem;
    }

    /* .home-about-section {
        padding-top: 0px !important;
    } */
}

@media only screen and (max-width: 572.5px) {
    .heading {
        padding-left: 0;
    }

    .home-content {
        display: flex;
        flex-direction: column;
    }

    .home-header {
        padding: 0px !important;
    }

    .home-content {
        padding: 5rem 1.8rem 2rem !important;
    }

    .image-container {
        padding-top: 4.5rem;
        padding-left: 0px;
    }

    .img {
        height: 90vw;
        width: 91vw;
    }

    .heading-main {
        font-size: 2.4em !important;
        padding-left: 30px !important;
    }

    .heading-name {
        font-size: 2.5em !important;
        padding-left: 30px !important;
    }

    .home-header div {
        font-size: 20px;
        padding-left: 1.1rem;
    }
}

@media only screen and (min-width: 150px) and (max-width: 309.5px) {
    .heading {
        padding-left: 0;
    }

    .home-content {
        display: flex;
        flex-direction: column;
        padding: 5rem 0rem 0rem !important;
    }

    .home-header {
        padding: 0px !important;
    }

    .image-container {
        padding-top: 4.5rem;
        padding-left: 0px;
    }

    .img {
        height: 90vw;
        width: 91vw;
    }

    .heading-main {
        font-size: 20px !important;
        padding-left: 15px !important;
    }

    .heading-name {
        font-size: 20px !important;
        padding-left: 15px !important;
    }

    .Typewriter__wrapper {
        font-size: 20px !important;
        font-weight: 500 !important;
        position: absolute !important;
    }

    .home-header div {
        font-size: 20px;
        padding-left: 9px;
    }


    .heading h1 {
        font-weight: 900;
        font-size: 18px;
        letter-spacing: 1px;
    }
}