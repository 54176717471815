.footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 0;
    align-items: center;
}


.footer-social-links {
    display: flex;
    justify-content: center;
}

.footer-social-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    height: 25px;
    width: 25px;
    transition: 0.5s !important;
    margin-left: 20px;
}

.footer-social-icon img {
    height: 20px;
    width: 20px;
}


.home-social {
    color: #fff;
}


.footer {
    background-color: rgb(10, 4, 22);
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important;
}

.footer-copywright {
    text-align: center !important;
}

.footer-body {
    z-index: 1;
    text-align: center !important;
}

@media (max-width: 767px) {
    .footer-copywright {
        text-align: center !important;
    }

    .footer-body {
        text-align: center !important;
    }
}

.copyright h3 {
    font-weight: normal;
    font-size: 1em;
    color: white !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.footer-icons {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
    padding: 0 !important;
}

.blockquote-footer {
    color: #a588c0 !important;
}

@media only screen and (max-width: 572.5px) {
    .copyright h3 {
        font-weight: normal;
        font-size: 14px;
        color: white !important;
        margin-top: 0.5em !important;
        margin-bottom: 0.5em !important;
    }

    .footer-icons {
        margin-top: 0.5em !important;
        margin-bottom: 0.5em !important;
        padding: 0 !important;
    }

    .footer {
        background-color: rgb(10, 4, 22);
        bottom: 0 !important;
        /* padding-top: 10px !important;
        padding-bottom: 8px !important; */
        padding: 10px 10px !important;
    }

    .footer-social-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #fff;
        height: 25px;
        width: 25px;
        transition: 0.5s !important;
        margin-left: 20px;
    }

    .footer-social-icon img {
        height: 20px;
        width: 20px;
    }

    .footer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 0;
        padding: 0;
        align-items: center;
    }
}

@media only screen and (max-width: 332.5px) {
    .copyright h3 {
        font-weight: normal;
        font-size: 13px;
        color: white !important;
        margin-top: 0.5em !important;
        margin-bottom: 0.5em !important;
    }

    .footer-icons {
        margin-top: 0.5em !important;
        margin-bottom: 0.5em !important;
        padding: 0 !important;
    }

    .footer {
        background-color: rgb(10, 4, 22);
        bottom: 0 !important;
        /* padding-top: 10px !important;
        padding-bottom: 8px !important; */
        padding: 10px 10px !important;
    }

    .footer-social-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #fff;
        height: 20px;
        width: 20px;
        transition: 0.5s !important;
        margin-left: 10px;
    }

    .footer-social-icon img {
        height: 20px;
        width: 20px;
    }

    .footer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 0;
        padding: 0;
        align-items: center;
    }
}

@media only screen and (max-width: 280.5px) {
    .copyright h3 {
        font-size: 16px;
    }

    .footer-social-icon {
        height: 25px;
        width: 25px;
    }

    .footer-social-icon img {
        height: 25px;
        width: 25px;
    }

    .footer {
        display: flex;
        flex-direction: column;
    }
}