.main-cnt {
    /* display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(3, 1fr);
    padding: 0 100px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.demo {
    margin-left: 10px;
}


.project-section {
    position: relative !important;
    padding-top: 70px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
}


.project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
    width: 30vw;
    /* margin: 0 25px; */
}

.project-card-view {
    box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
    color: white !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
}

.project-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
}

/* .blog-card-view {
    background-color: transparent !important;
    box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
    color: white !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
} */

/* .blog-link {
    color: white !important;
    text-decoration: none !important;
}

.blog-link:hover {
    cursor: pointer !important;
}

.blog-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
} */

.card-img-top {
    padding: 20px !important;
    opacity: 0.8 !important;
    border-radius: 10px !important;
}

/* .blog-img {
    padding: 0px !important;
    opacity: 0.8 !important;
    border-radius: 0px !important;
} */

.btn-primary {
    color: #fff !important;
    background-color: #623686 !important;
    border-color: #623686 !important;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: #6d20c5d7 !important;
    border-color: #6d20c5d7 !important;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.project-heading {
    color: white !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
}



.project-card-view img {
    height: 15vw;
    width: 24vw;
    padding: 10px;
}

.text-cnt p {
    padding: 0 15px;
    text-align: justify;
}

/* .text-cnt h2 {
    margin-top: 0;
} */

.cnt p {
    color: #fff;
}

.bttn a {
    text-decoration: none;
    background-color: #623686;
    color: #fff;
    border-radius: 5px;
    border: 2px solid rebeccapurple;
    padding: 10px 15px;
}

.cnt {
    margin-bottom: 50px;
}

.bttn {
    justify-content: center;
    text-align: center;
    margin: 50px 0;
}

.github:hover,
.demo:hover {
    background-color: violet;
    border-color: violet;
    color: black;
    font-weight: 700;
}


@media only screen and (max-width: 767.5px) {
    .main-cnt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
    }

    .project-card-view img {
        height: 35vw;
        width: 58vw;
    }

    .project-card {
        height: auto !important;
        width: 72vw;
    }

    .text-cnt {
        padding-bottom: 1px;
    }
}

@media only screen and (max-width: 332.5px) {


    .project-card-view img {
        height: 18vw;
        width: 35vw;
        height: 70%;
        width: 99%;
    }


    .project-card {
        height: auto !important;
        width: 99%;
    }
    .bttn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .bttn a {
        margin: 5px 0;
        padding: 6px 6px;
    }

    .text-cnt p {
        padding: 0 5px;
        font-size: 7px;
    }

    .cnt p {
        padding: 0 5px;
        font-size: 14px;
    }

    .text-cnt h2 {
        font-size: 18px;
    }
}





@media (max-width: 296.5px) {
    .project-heading {
        font-size: 26px !important;
    }
}

@media (max-width: 200.5px) {
    .project-heading {
        font-size: 22px !important;
    }
}